@import "../../../styles/mixins/global.scss";

.headerTopAlignPage {
  position: absolute;
  top: 0;
  z-index: 99999999999;
  width: 100%;
}

.heroTextStyleAlignment {
  padding: map-get($pixel, xl) * 9 + map-get($pixel, md) * 2 0 0 0;
  @include breakpoint("max-sm") {
    padding: 205px 0 0 0;
  }
  .subGrid {
    @include grid-layout;
    grid-template-columns: 417px;
    gap: 0;
    position: map-get($layout, relative);

    justify-content: flex-end;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
    .subGridItems:nth-child(1) {
      padding: 170px 0 0 0;
      @include breakpoint("max-sm") {
        padding: 0;
      }
    }
    .subGridItems {
      p {
        @include heading-4;
        font-size: 20px;
        margin: 0 0 map-get($pixel, md) * 3 0;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.8);
        @include breakpoint("max-sm") {
          @include heading-4;
          margin-bottom: 40px;
        }
      }
      .buttonAlignment {
        @include flex-center;
        @include breakpoint("max-sm") {
          justify-content: center;
        }
        button {
          padding: map-get($pixel, sm) map-get($pixel, sm) + 4px;
          min-width: 158px;
          border-radius: map-get($pixel, md) * 2 + map-get($pixel, sm);
          @include text-sm-bold;
          height: map-get($pixel, md) * 4 + 4px;
          cursor: map-get($layout, pointer);
          @include flex-center;
          justify-content: center;
          transition: 0.3s ease-in-out;
          @include breakpoint("max-sm") {
            min-width: 152px;
            justify-content: space-between;
            padding: 5px 20px;
          }
          span {
            padding-right: map-get($pixel, md) - 2px;
          }
        }
        .whiteButtonDesign {
          background-color: map-get($colors, white-color);
          color: map-get($colors, black-3);
          border: none;
          margin-right: map-get($pixel, md) - 2px;
          &:hover {
            background-color: map-get($colors, black-1);
            color: map-get($colors, white-color);
            svg {
              path {
                fill: map-get($colors, white-color);
              }
            }
          }
          @include breakpoint("max-sm") {
            margin-right: 5px;
          }
        }
        .heroButtonOutline {
          border: 1px solid map-get($colors, white-color);
          background-color: transparent;
          color: map-get($colors, white-color);
          @include breakpoint("max-sm") {
            margin-left: 5px;
          }
          &:hover {
            background-color: map-get($colors, black-1);
            color: map-get($colors, white-color);
            border: 1px solid map-get($colors, black-1);
            svg {
              path {
                fill: map-get($colors, white-color);
              }
            }
          }
        }
      }
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) 539px;
    gap: 0 190px;
    position: map-get($layout, relative);
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: map-get($pixel, md) * 8 0;
    }
    @include breakpoint("max-sm") {
      gap: 78px 0;
    }
    .gridItems {
      h1 {
        color: map-get($colors, white-color);
        margin: 0;
        @include heading-large;
        @include breakpoint("max-sm") {
          @include heading-6;
          font-size: 50px;
          line-height: 60px;
        }
      }
    }
  }
}

.heroTextVideoAlignment {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  z-index: 99999;
}

video {
  min-height: 100vh;
  object-fit: cover;
  width: 100%;
}

.videoBlur::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 4px);
  content: "";
  @include breakpoint("max-sm") {
    height: calc(100% - 1px);
  }
}

.mobileViewVideoShow {
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
}

.mobileViewVideoNone {
  @include breakpoint("max-sm") {
    display: none;
  }
}

.heroMobileImage {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: none;

  @include breakpoint("max-sm") {
    display: block;

    img {
      width: auto;
      height: 100vh;
      min-height: 750px;
      object-fit: cover;
      // object-position: 0px;
    }
  }
}
