@import '../../../styles/mixins/global.scss';

.PlaceratTellusAlignment {
    padding: 0 0 map-get($pixel, md ) * 16 0;
    @include breakpoint("max-sm") {
        display: none;
    }
    .grid {
        @include grid-layout;
        grid-template-columns: repeat(2 , 1fr);
        gap: 0 map-get($pixel, md ) * 6;
        .gridItems {
            h1 {
                @include heading-1;
                color: map-get($colors, black-3);
            }
            p {
                @include text;
                margin: 0;
                line-height: map-get($pixel, md ) * 2 + 2px;
                color: map-get($colors, black-4 );
            }
        }
    }
}