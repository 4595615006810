@import '../../../styles/mixins/global.scss';

.CurabiturSectionAlignment {
    padding: map-get($pixel, md) * 16 0;
    @include breakpoint("max-md") {
        padding: 148px 0 86px 0;
    }
    .grid {
        @include grid-layout;
        grid-template-columns: 1fr 590px;
        align-items: center;
        gap: 0 map-get($pixel, md ) * 8;
        justify-content: space-between;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 60px 0; 
        }
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 32px 0; 
        }
        .gridItems {
            img{
                width: 100%;
                height: 600px;
                object-fit: cover;
                @include breakpoint("max-sm") {
                    height: 300px;
                }
            }
            a {
                @include text-md;
                color: map-get($colors , gray-1 );
                font-family: 'Playfair Display', serif;
                text-transform: uppercase;
                font-weight: 500;
            }
            h1 {
                margin: map-get($pixel,md ) * 2 0 map-get($pixel,md ) * 4 0;
                @include heading-1;
                color: map-get($colors, black-3 );
                @include breakpoint("max-sm") {
                    margin: 0 0 34px 0;
                }
            }
            p {
                @include text;
                color: map-get($colors, black-4 );
                line-height: map-get($pixel, md ) * 2 + 2px;
                margin: 0 0 map-get($pixel, md ) * 2 0;
            }
            p:last-child {
                margin: 0;
            }
        }
    } 
}