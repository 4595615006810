@import "../../styles/mixins/global.scss";

.SustainabilityHeader {
  // padding: 0 map-get($pixel, md ) * 6 ;
  .headerAlignment {
    @include flex-between;
    padding: map-get($pixel, xl) * 2 0;
    position: map-get($layout, relative);
    border-bottom: 1px solid #c5c5c5;
    .logo {
      img {
        cursor: map-get($layout, pointer);
      }
    }
    .mobileMenu {
      display: none;
      @include breakpoint("max-md") {
        display: block;
      }
    }
    .Menu {
      @include breakpoint("max-md") {
        display: none;
      }
      nav {
        ul {
          @include flex-center;
          list-style: none;
          li,
          a {
            text-decoration: none;
            position: map-get($layout, relative);
            margin-left: map-get($pixel, xl) * 3;
            @include text-md;
            color: map-get($colors, black-4);
            cursor: map-get($layout, pointer);
          }
          li:last-child {
            margin-right: map-get($pixel, null);
          }
        }
      }
    }
  }

  .Menu ul li {
    overflow: hidden;
  }
  .Menu ul li span:first-child {
    transform: translateY(0);
    display: inline-block;
    transition: 0.2s ease-in-out;
  }

  .Menu ul li:hover > span {
    transform: translateY(-100%);
    display: inline-block;
    transition: 0.2s ease-in-out;
  }

  .Menu ul li:hover span:last-child {
    transform: translateY(0);
    transition: 0.2s ease-in-out;
  }
  .Menu ul li span:last-child {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(100%);
  }
}

.SustainabilityPageAlignment {
  padding: map-get($pixel, md) * 10 0 map-get($pixel, md) * 12 0;
  @include breakpoint("max-md") {
    padding: 80px 0;
  }
  @include breakpoint("max-sm") {
    padding: 60px 0 60px 0;
  }
  .pageTitle {
    h1 {
      @include heading-large;
      max-width: 771px;
      color: map-get($colors, black-3);
      margin: 0;
      font-weight: 500;
      @include breakpoint("max-md") {
        max-width: 650px;
        @include heading-1;
      }
      @include breakpoint("max-sm") {
        max-width: 100%;
        @include heading-9;
      }
    }
  }
}

.SustainabilityPageBanner {
  padding: 0 0;
  img {
    width: 100%;
    height: 600px;
    @include breakpoint("max-md") {
      height: 400px;
    }
  }
}

.SustainabilityMobileAlignment {
  @include breakpoint("max-sm") {
    padding: 0px;
  }
}
