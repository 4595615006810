@import "../../../styles/mixins/global.scss";

.pioneeringSectionAlignment {
  padding: map-get($pixel, xl) * 8 0;
  @include breakpoint("max-sm") {
    padding: 80px 0;
  }
  .withoutContainerAlignment {
    padding-right: max(80px, calc((100vw - 1120px) / 2));
    @include breakpoint("max-md") {
      padding-right: 40px;
    }
    @include breakpoint("max-sm") {
      padding-right: 30px;
    }
    .techImageGrid {
      @include grid-layout;
      grid-template-columns: 598px minmax(0, 1fr);
      gap: 0 132px;
      justify-content: space-between;
      @include breakpoint("max-md") {
        grid-template-columns: repeat(2, 1fr);
        gap: 0 40px;
      }
      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 60px 0;
      }
      .techImageGridItems {
        img {
          width: 100%;
          height: 497px;
          object-fit: cover;
          @include breakpoint("max-sm") {
            height: 282px;
          }
        }
        .newTextGrid {
          @include grid-layout;
          grid-template-columns: repeat(2, 1fr);
          gap: map-get($pixel, md) * 4;
          @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
          }
          @include breakpoint("max-sm") {
            padding-left: 30px;
          }
          .newTextGridItems {
            h4 {
              @include heading-2;
              color: map-get($colors, black-3);
              margin: 0 0 map-get($pixel, md) * 2 0;
              @include breakpoint("max-sm") {
                @include text-md-playfir;
              }
            }
            p {
              @include text;
              color: map-get($colors, black-4);
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .containerSpaceRemove {
    padding: 0px;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 1fr;
    gap: 0 132px;
    align-items: map-get($layout, center);
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 80px 0;
    }
    @include breakpoint("max-md") {
      gap: 40px 0;
    }
    @include breakpoint("max-sm") {
      .gridItems:nth-child(1) {
        order: 2;
        padding: 0 30px;
      }
    }
    .gridItems {
      img {
        width: map-get($layout, w-full);
        height: 410px;
        object-fit: cover;
        @include breakpoint("max-sm") {
          height: 320px;
        }
      }
      .childGrid {
        @include grid-layout;
        grid-template-columns: repeat(4, 1fr);
        gap: map-get($pixel, md) * 4;
        justify-content: space-between;
        @include breakpoint("max-sm") {
          grid-template-columns: repeat(1, 1fr);
          gap: 40px;
        }

        .childGridItems {
          h2 {
            color: map-get($colors, black-3);
            @include heading-2;
            margin-bottom: map-get($pixel, md) * 2;
            @include breakpoint("max-sm") {
              @include text-md-playfir;
            }
          }
          p {
            @include text;
            color: map-get($colors, black-4);
            line-height: map-get($pixel, md) * 2 + 2px;
          }
        }
      }
    }
  }
  .textGrid {
    @include grid-layout;
    grid-template-columns: 426px 292px;
    justify-content: space-between;
    gap: 0 378px;
    padding: 0 0 map-get($pixel, md) * 8 + 0px 0;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: 426px minmax(0, 1fr);
    }
    @include breakpoint("max-md") {
      padding: 60px 0;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 0px 0;
      padding: 0 30px;
      padding-bottom: 20px;
    }
    @include breakpoint("max-sm") {
    }
    .textGridItems {
      img {
        width: 100%;
        height: 280px;
        @include breakpoint("max-sm") {
          height: 228px;
          display: none;
        }
      }
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
        display: block;
        text-align: left;
        @include breakpoint("max-sm") {
          text-align: left;
          padding-bottom: 20px;
        }
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        line-height: map-get($pixel, md) * 2 + 2px;
        margin: 0 0 map-get($pixel, md) * 4 0;
        @include breakpoint("max-sm") {
          margin: 0 0 40px 0;
        }
      }
      button {
        padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
        background-color: map-get($colors, black-1);
        color: map-get($colors, white-color);
        min-width: 146px;
        height: 44px;
        cursor: map-get($layout, pointer);
        @include text-sm-bold;
        border: none;
        @include flex-center;
        justify-content: center;
        transition: 0.3s ease-in-out;
        border-radius: map-get($pixel, md) * 2 + 2px + 2px;
        @include breakpoint("max-sm") {
          display: none;
        }
        &:hover {
          background-color: transparent;
          color: map-get($colors, black-1);
          border: 1px solid map-get($colors, black-1);
          svg {
            path {
              fill: map-get($colors, black-1);
            }
          }
        }
        @include breakpoint("max-sm") {
          padding: 5px 20px;
          justify-content: space-between;
        }
        span {
          padding-right: map-get($pixel, md) - 2px;
        }
      }

      h1 {
        @include heading-1;
        margin: 0 0 map-get($pixel, sm) * 4 0;
        color: map-get($colors, black-3);
        line-height: map-get($pixel, md) * 5 + 2px;
        @include breakpoint("max-sm") {
          @include heading-6;
        }
      }
    }
  }
}

.mobileViewPioneeringButtonShow {
  display: none;
  padding: 50px 0 0 0;
  @include breakpoint("max-sm") {
    display: block;
  }
  button {
    padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
    background-color: map-get($colors, black-1);
    color: map-get($colors, white-color);
    min-width: 146px;
    height: 44px;
    cursor: map-get($layout, pointer);
    @include text-sm-bold;
    border: none;
    @include flex-center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    border-radius: map-get($pixel, md) * 2 + 2px + 2px;
    &:hover {
      background-color: transparent;
      color: map-get($colors, black-1);
      border: 1px solid map-get($colors, black-1);
      svg {
        path {
          fill: map-get($colors, black-1);
        }
      }
    }
    @include breakpoint("max-sm") {
      padding: 5px 20px;
      justify-content: space-between;
    }
    span {
      padding-right: map-get($pixel, md) - 2px;
    }
  }
}

.urbanregenerationMobileImage {
  padding-left: 30px;
  padding-bottom: 20px;
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
  img {
    width: 100%;
    height: 228px;
    object-fit: cover;
  }
}
