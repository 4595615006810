@import "../../../styles/mixins/global.scss";

.LrcGroupsAlignment {
  padding: 160px 0;
  @include breakpoint("max-sm") {
    padding: 20px 0 127px 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 427px minmax(0, 1fr);
    gap: 0 130px;
    justify-content: space-between;
    @include breakpoint("max-md") {
      gap: 0 40px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
      padding: 80px 0 0 0;
    }
    .gridItems:nth-child(1) {
      div {
        position: sticky;
        top: 40px;
      }
    }
    .gridItems {
      .subGrid {
        @include grid-layout;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px 20px;
        justify-content: space-between;
        @include breakpoint("max-md") {
          grid-template-columns: repeat(1, 1fr);
          gap: 40px 0;
        }
        @include breakpoint("max-sm") {
          gap: 30px 0;
        }
        .subGridItems {
          .iconBoxDesign {
            width: map-get($pixel, xl) * 5;
            height: map-get($pixel, xl) * 5;
            border: 1px dashed #7b90af;
            @include flex-center;
            justify-content: center;
          }
          p {
            @include text;
            color: map-get($colors, black-4);
            line-height: map-get($pixel, md) * 2 + 2px;
            padding-right: 1rem;
            @include breakpoint("max-sm") {
              margin-bottom: 0px;
              padding-left: 0px;
            }
          }
          h1 {
            @include heading-2;
            color: map-get($colors, black-3);
            margin: map-get($pixel, md) * 2 0;
            font-weight: 400;
            @include breakpoint("max-sm") {
              @include heading-2;
              font-size: 18px;
              line-height: 21px;
              font-weight: 500;
            }
          }
        }
      }
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        line-height: map-get($pixel, md) * 5 + 2px;
        margin: 0 0 map-get($pixel, md) * 2 0;
        @include breakpoint("max-sm") {
          @include heading-6;
        }
      }
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        display: block;
        text-transform: uppercase;
      }
      p {
        @include text-md;
        color: map-get($colors, black-4);
        margin: 0 0 map-get($pixel, md) * 4 0;
        line-height: 26px;
        @include breakpoint("max-sm") {
          margin: 0 0 40px 0;
        }
      }
    }
  }
}

.benefitsScaleBaner {
  background-image: url("../../../Assets/Images/banner.png");
  background-size: cover;
  width: map-get($layout, w-full);
  height: 523px;
  background-repeat: no-repeat;
  position: map-get($layout, relative);
  @include flex-center;
  @include breakpoint("max-sm") {
    background-image: url("../../../Assets/Images/banner-mobile.png");
  }
  .bannerText {
    max-width: 558px;
    height: 100%;
    @include breakpoint("max-sm") {
      max-width: 100%;
    }
    h1 {
      @include heading-1;
      color: map-get($colors, white-color);
      line-height: map-get($pixel, md) * 5 + 2px;
      position: relative;
      font-weight: 400;
      @include breakpoint("max-sm") {
        @include heading-1;
        line-height: 52px;
        padding-right: 1rem;
        font-weight: 400;
      }
    }
  }
}

.benefitsScaleBaner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: map-get($layout, w-full);
  height: map-get($layout, h-full);
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.newsSectionAlignment {
  padding: map-get($pixel, md) * 16 0 0 0;
  @include breakpoint("max-sm") {
    padding: 80px 0 0 0;
  }
  .viewMoreButton {
    @include flex-center;
    justify-content: map-get($layout, center);
    button {
      background-color: map-get($colors, black-1);
      min-width: 124px;
      height: 50px;
      @include flex-center;
      justify-content: center;
      @include text-sm-bold;
      position: relative;
      z-index: 9999;
      color: map-get($colors, white-color);
      cursor: map-get($layout, pointer);
      padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
      border: none;
      border-radius: map-get($pixel, md) * 2 + 4px;
      transition: 0.3s ease-in-out;
      svg {
        margin-left: map-get($pixel, md) - 2px;
      }
      &:hover {
        border: 1px solid map-get($colors, black-1);
        color: map-get($colors, black-1);
        background-color: transparent;
        svg {
          path {
            fill: map-get($colors, black-1);
          }
        }
      }
      @include breakpoint("max-sm") {
        width: 100%;
        justify-content: center;
        padding: 5px 20px;
      }
    }
  }
  .cardGrid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 0 map-get($pixel, md) * 8 0;
    gap: 0 map-get($pixel, md) * 2;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
      padding: 0 0 40px 0;
    }
    .cardGridItems {
      .cardDetails {
        padding: map-get($pixel, md) * 2 + 5px 0 0 0;
        h3 {
          @include heading-2;
          color: map-get($colors, black-3);
          margin: 0;
          font-weight: 600;
          padding: 0 0 map-get($pixel, sm) * 3 + 1px 0;
          border-bottom: 1px solid #ededed;
          cursor: pointer;
        }

        p {
          @include text;
          color: map-get($colors, black-4);
          margin: map-get($pixel, md) + 6px 0 0 0;
          a {
            text-decoration: underline;
          }
        }
      }
      .cardImage {
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          @include breakpoint("max-sm") {
            height: 260px;
          }
        }
      }
    }
  }

  .pageTitle {
    padding: 0 0 map-get($pixel, md) * 8 0;
    @include breakpoint("max-sm") {
      padding: 0 0 40px 0;
    }
    a {
      @include text-md-playfir;
      color: map-get($colors, blue-text-color);
      text-transform: uppercase;
      display: block;
      padding: 0 0 map-get($pixel, md) * 2 0;
    }
    h1 {
      @include heading-1;
      color: map-get($colors, black-3);
      margin: 0;
      @include breakpoint("max-sm") {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}
