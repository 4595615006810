@import "../../../styles/mixins/global.scss";

.CurabiturBanner {
  background-image: url("../../../Assets/Images/CurabiturBanner.png");
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  object-fit: cover;
  @include flex-center;
  @include breakpoint("max-sm") {
    height: auto;
    padding: 112px 0 280px 0;
  }
  h1 {
    @include heading-1;
    color: map-get($colors, white-color);
    margin: 0;
    font-weight: 400;
    width: 650px;
    @include breakpoint("max-sm") {
      width: 100%;
    }
  }
}
.CurabiturBanner::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.orciVariusAlignment {
  padding: map-get($pixel, md) * 16 0 map-get($pixel, md) * 16 + 8px 0;
  @include breakpoint("max-md") {
    padding: 100px 0;
  }
  .cardGrid {
    @include grid-layout;
    grid-template-columns: 537px minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 13 + 3px;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: 0 40px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 80px 0;
      padding: 0 0 86px 0;
    }
    .cardGridItems:nth-child(2) {
      display: flex;
      align-items: flex-end;
      img {
        width: 100%;
        height: 550px;
        object-fit: cover;
        @include breakpoint("max-md") {
          height: 350px;
        }
      }
    }
    .cardGridItems {
      .cardImage {
        img {
          width: 100%;
          height: 550px;
          object-fit: cover;
          @include breakpoint("max-md") {
            height: 328px;
          }
          @include breakpoint("max-sm") {
            height: 300px;
          }
        }
      }
    }
    .cardDetails {
      padding: map-get($pixel, md) * 8 0 0 0;
      p {
        @include text;
        color: map-get($colors, black-4);
        line-height: map-get($pixel, md) * 2 + 2px;
      }
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 537px minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 13 + 3px;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: 0 40px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
    }
    .gridItems {
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        display: block;
        font-weight: 500;
        justify-content: flex-end;
        text-transform: uppercase;
        text-align: map-get($layout, right);
        @include breakpoint("max-sm") {
          display: none;
        }
      }
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, md) * 3 + 4px 0;
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        line-height: map-get($pixel, md) * 2 + 2px;
        margin: 0 0 58px 0;
        @include breakpoint("max-sm") {
          margin: 0 0 30px 0;
        }
      }
    }
  }
}

.cityTopAlignment {
  padding: 200px 0 0 0;
  @include breakpoint("max-sm") {
    padding: 60px 0 0 0;
  }
  img {
    width: 100%;
    height: 428px;
    object-fit: cover;
    @include breakpoint("max-sm") {
      height: 303px;
    }
  }
}

.cardDetailsOne {
  padding: 80px 0 0 0;
  p {
    @include text;
    color: map-get($colors, black-4);
    line-height: 22px;
    font-weight: 400;
    font-size: 16px;
    margin: 0 !important;
  }
}

.EcologicalTdeologyMobile {
  display: none;
  h6 {
    color: map-get($colors, blue-text-color);
    @include text-md-playfir;
text-transform: uppercase;
    margin: 0 0 20px 0;
  }
  @include breakpoint("max-sm") {
    display: block;
  }
}

.mobileViewOrder {
  display: grid;
  .cityTopAlignment {
    @include breakpoint("max-sm") {
      order: 2;
    }
  }
}
