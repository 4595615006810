@import "../../../styles/mixins/global.scss";

.aboutGroupAlignment {
  padding: map-get($pixel, xl) * 8 0 map-get($pixel, xl) * 6 0;
  @include breakpoint("max-sm") {
    padding: 80px 0;
  }
  .whoWeAreGrid {
    @include grid-layout;
    grid-template-columns: 426px minmax(0, 1fr);
    gap: 0 200px;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
    .whoWeAreGridItems {
      .textBottomAlignment {
        padding: 0 0 map-get($pixel, sm) * 3 - 1px 0;
      }
      h3 {
        font-size: 96px;
        line-height: 127px;
        color: map-get($colors, black-3);
        font-weight: 500;
        letter-spacing: -0.01em;
        font-family: "Playfair Display", serif;
        margin: 0 0 map-get($pixel, sm) + 3px 0;
        @include breakpoint("max-sm") {
          @include heading-large;
          line-height: 70px;
        }
      }
      h6 {
        @include text;
        color: map-get($colors, black-4);
      }
      a {
        @include text-md-playfir;
        text-transform: uppercase;
        color: map-get($colors, blue-text-color);
        padding: 0 0 8px 0;
        @include breakpoint("max-sm") {
          display: block;
          padding: 0 0 37px 0;
        }
      }
      h1 {
        @include heading-1;
        margin: 0 0 map-get($pixel, md) * 3 + map-get($pixel, sm) + 1px 0;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, md) * 3 + 2px 0;
        @include breakpoint("max-sm") {
          @include heading-6;
          margin-bottom: 40px;
          font-size: 40px;
          margin: 0 0 35px 0;
        }
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        margin: 0 0 map-get($pixel, md) * 4 + 0px 0;
        line-height: map-get($pixel, md) * 2 + 3px;
        width: 426px;
        @include breakpoint("max-sm") {
          margin-bottom: 40px;
          width: 100%;
        }
      }
      button {
        background-color: map-get($colors, black-1);
        min-width: 146px;
        height: 44px;
        @include flex-center;
        justify-content: center;
        @include text-sm-bold;
        position: relative;
        z-index: 9999;
        color: map-get($colors, white-color);
        cursor: map-get($layout, pointer);
        padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
        border: none;
        border-radius: map-get($pixel, md) * 2 + 4px;
        transition: 0.3s ease-in-out;
        svg {
          margin-left: map-get($pixel, md) - 2px;
        }
        &:hover {
          border: 1px solid map-get($colors, black-1);
          color: map-get($colors, black-1);
          background-color: transparent;
          svg {
            path {
              fill: map-get($colors, black-1);
            }
          }
        }
        @include breakpoint("max-sm") {
          justify-content: space-between;
          padding: 5px 20px;
        }
      }
    }
  }
}

.groupNumberAlignment {
  .grid {
    @include grid-layout;
    grid-template-columns: 426px minmax(0, 1fr);
    gap: 0 130px;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 80px 0;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
    @include breakpoint("max-sm") {
      .gridItems:nth-child(1) {
        order: 2;
      }
      .gridItems:nth-child(2) {
        order: 1;
      }
    }

    .gridItems {
      h1 {
        @include heading-1;
        color: map-get($colors, black-1);
        margin: 0 0 map-get($pixel, md) * 2 + map-get($pixel, sm) + 1px 0;
        @include breakpoint("max-sm") {
          @include heading-6;
        }
        br {
          @include breakpoint("max-sm") {
            display: none;
          }
        }
      }
      .listAlignment {
        ul {
          list-style: none;
          li {
            padding: map-get($pixel, md) * 3 0;
            @include heading-5;
            color: map-get($colors, gray-1);
            border-bottom: 1px solid map-get($colors, border-color);
            @include breakpoint("max-sm") {
              font-size: 20px;
            }
            span {
              color: map-get($colors, black-3);
              font-style: italic;

              font-family: "Playfair Display" !important;
              // font-family: "Lora", serif;
              font-size: 24px;
            }
          }
          li:last-child {
            padding-bottom: 0;
            border: none;
          }
        }
      }
      .cardImage {
        img {
          width: map-get($layout, w-full);
          height: 550px;
          object-fit: cover;
          @include breakpoint("max-sm") {
            height: 290px;
          }
        }
      }
      .cardDetails {
        padding: map-get($pixel, md) * 4 + 5px 0 0 0;
        @include breakpoint("max-sm") {
          padding: map-get($pixel, md) * 2 + 5px 0 0 0;
        }
        p {
          @include text;
          margin: 0;
          font-size: 12px;
          color: map-get($colors, black-4);
          line-height: map-get($pixel, md) * 2 2px + 1px;
        }
      }
    }
  }
}

@include breakpoint("max-sm") {
  .mobileViewgroupNumberAlignment {
    padding: 0;
  }
}
