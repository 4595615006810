@import '../../../styles/mixins/global.scss';

.MalesuadaAlignment {
    padding: map-get($pixel,md ) * 16 0;
    @include breakpoint("max-md") {
        padding: map-get($pixel, md ) * 12 0;
    }
    @include breakpoint("max-sm") {
        padding: map-get($pixel, md ) * 8 0;
    }
    .grid {
        @include grid-layout;
        grid-template-columns: 1fr 426px;
        gap: 0 248px;
        align-items: center;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 60px 0;
        }
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px 0;
        }
        .gridItems {
            img {
                width: 100%;
                height: 428px;
                object-fit: cover;
                @include breakpoint("max-sm") {
                    height: 319px;
                }
            }
            h1 {
                @include heading-1;
                margin: 0 0 map-get($pixel, md ) * 3 + 4px 0;
                color: map-get($colors, black-3 );
            }
            p {
                @include text;
                color: map-get($colors, black-4 );
                line-height: map-get($pixel, md ) * 2 + 4px;
            }
        }
    }
}