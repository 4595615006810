@import "../../../styles/mixins/global.scss";

.MaurisMaximusAlignment {
  background-color: map-get($colors, backgound-2);
  padding: 196px 0 160px 0;
  @include breakpoint("max-md") {
    padding: 120px 0;
  }
  @include breakpoint("max-md") {
    padding: 80px 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 427px minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 13 + 1px;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
    }
    .gridItems {
      .childGrid {
        @include grid-layout;
        grid-template-columns: repeat(2, 1fr);
        gap: map-get($pixel, md) * 6 map-get($pixel, md) * 4;
        @include breakpoint("max-sm") {
          padding: 60px 0 0 0;
          grid-template-columns: repeat(1, 1fr);
          gap: 60px 0;
        }
        .childGridItems {
          h2 {
            @include heading-2;
            color: map-get($colors, black-3);
            font-weight: 400;
            line-height: map-get($pixel, md) * 2 + 8px;
            margin: 0 0 map-get($pixel, md) * 2 0;
          }
          p {
            @include text;
            color: map-get($colors, black-4);
            margin: 0;
            line-height: map-get($pixel, md) * 2 + 2px;
          }
        }
      }
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, md) * 2 0;
        @include breakpoint("max-sm") {
          padding-top: 30px
        }
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        margin: 0 0 map-get($pixel, md) * 4 0;
        line-height: map-get($pixel, md) * 2 + 2px;
      }
      a {
        @include text-md-playfir;
        display: block;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
      }
    }
  }
}
